<template>
  <CRow>
    <CCol col="12" lg="12">
      <div class="row">
        <CCol sm="12" class="align-self-center">
          <div class="text-sm-right mt-3 mt-sm-0">
            <CButtonGroup class="file-export">
              <CButton color="secondary" disabled>
                {{ $lang.buttons.export.title }}
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.export.pdf"
                  v-on:click="exports(self, 'pdf', module, 'Student Skill',editId)"
              >
                <i class="fas fa-file-pdf"></i>
              </CButton>
              <VueJsonToCsv
                  class="btn btn-success"
                  v-c-tooltip="$lang.buttons.export.excel"
                  :json-data="json_data"
                  :labels="json_label"
                  :csv-title="json_title"
              >
                <i class="fas fa-file-excel"></i>
              </VueJsonToCsv>
            </CButtonGroup>
          </div>
        </CCol>
      </div>
      <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable" @loaded="exports(self,'Excel',module,'Student Skill',self.editId)">
        <template #skill="data">
          {{ data.row.skill.title }}
        </template>
      </v-server-table>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import { student } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";
import { ServerTable } from "vue-tables-2";
import VueJsonToCsv from "vue-json-to-csv";
function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}
Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "StudentSkill",
  mixins: [Mixin],
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      submitted: false,
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: student,
      userOptions: [],
      editId: this.$route.params.id,
      listUrl: "",
      columns: [
        "skill",
      ],
      data: [],
      options: {
        headings: {
          skill: this.$lang.studentSkill.table.skill,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/student/skill/";
    self.editId = this.$route.params.id;
    self.listUrl = this.listUrl + self.editId;
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
  },
};
</script>
<style>
div .error {
  color: red;
}
.hasError label {
  color: red;
}
</style>
