<template>
  <CRow>
    <CCol col="12" lg="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <table class="table table-bordered">
        <tr>
          <th>{{ $lang.student.form.logo }}</th>
          <td><img alt="Company Logo" @click="open(students.logo)" :src="students.logo" class="rounded-circle" height="auto" width="50%"/></td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.nickname }}</th>
          <td>{{ students.nickname }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.firstName }}</th>
          <td>{{ students.firstName }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.lastName }}</th>
          <td>{{ students.lastName }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.email }}</th>
          <td>{{ students.email }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.description }}</th>
          <td>{{ students.description }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.enrollmentNo }}</th>
          <td>{{ students.enrollmentNo }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.enrollmentYear }}</th>
          <td>{{ students.enrollmentYear }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.interestArea }}</th>
          <td>{{ students.interestArea }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.internType }}</th>
          <td>{{ students.internType }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.passingYear }}</th>
          <td>{{ students.passingYear }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.phoneNumber }}</th>
          <td>{{students.countryCode }} {{ students.username }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.collegeStream }}</th>
          <td>{{ students.collegeStream }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.college }}</th>
          <td>{{ students.college }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.collegeDiscipline }}</th>
          <td>{{ students.collegeDiscipline }}</td>
        </tr>
        <tr>
          <th>{{ $lang.student.form.collegeProgramme }}</th>
          <td>{{ students.collegeProgramme }}</td>
        </tr>
      </table>
      <CButton color="danger" size="sm" @click="cancel">
        <CIcon name="cil-ban"/>
        {{ $lang.buttons.general.crud.cancel }}
      </CButton>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import { student } from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import moment from "moment";
import VueTelInput from "vue-tel-input-fix";
import "vue-tel-input-fix/dist/vue-tel-input.css";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueToasted);
Vue.use(VueTelInput);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "StudentDetail",
  mixins: [Mixin],
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        validCharactersOnly: true,
      },
      submitted: false,
      amountField: "",
      viewImage: "",
      largeModal: false,
      persontageField: "",
      userField: "",
      companyField: "",
      err_msg: "",
      dismissCountDown: 0,
      self: this,
      module: student,
      userOptions: [],
      editId: this.$route.params.id,
      students: {
        countryCode : "",
        description : "",
        nickname : "",
        email : "",
        enrollmentNo : "",
        enrollmentYear : "",
        firstName : "",
        interestArea : "",
        internType : "",
        lastName : "",
        passingYear : "",
        username : "",
        collegeProgramme : "",
        collegeStream : "",
        collegeDiscipline : "",
        college : "",
      },
    };
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    axios
        .get('/student/'+self.editId)
        .then((response) => {
          let student = response.data.data;
          self.students.countryCode = student.countryCode;
          self.students.description = student.description;
          self.students.nickname = student.nickname;
          self.students.email = student.email;
          self.students.enrollmentNo = student.enrollmentNo;
          self.students.enrollmentYear = student.enrollmentYear;
          self.students.firstName = student.firstName;
          self.students.interestArea = student.interestArea;
          self.students.internType = student.internType;
          self.students.lastName = student.lastName;
          self.students.passingYear = student.passingYear;
          self.students.username = student.username;
          self.students.collegeProgramme = student.collegeProgramme[0].name;
          self.students.collegeStream = student.collegeStream[0].name;
          self.students.collegeDiscipline = student.collegeDiscipline[0].name;
          self.students.college = student.college.name;
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  methods: {
    open(url) {
      let self = this;
      self.viewImage = url;
      self.largeModal = true;
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    sendAlert() {
      this.$root.$emit("alert", "test1", "test2");
    },
    cancel() {
      this.usersOpened
          ? this.$router.go(-1)
          : this.$router.push({path: "/student"});
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
